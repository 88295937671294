import * as React from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
  Skeleton,
} from "@mui/material";
import { connect } from "react-redux";
import {
  Navigate,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import {
  centered_flex_box,
  left_flex_box,
  main_button,
  MainInput,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getOrders } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";
import NewCard from "../app/components/NewCard";
import Ellipse from "../app/components/Ellipse";
import { BarChart } from "@mui/x-charts";

export const Returns = ({
  getOrders,
  user,
  growthPercentage,
  returnAnalysis,
  orders,
  isLoading
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = React.useState(null);
  const [terms, setTerms] = React.useState(false);
  const [page, setPage] = React.useState(parseInt(searchParams.get('page')) || 1);
  const [pageSize, setPageSize] = React.useState(parseInt(searchParams.get('pageSize')) || 10);
  const [startDate, setStartDate] = React.useState(moment(searchParams.get('startDate') || moment().startOf("month")))
  const [endDate, setEndDate] = React.useState(moment(searchParams.get('endDate') || moment().endOf("month")))
  const [filters, setFilters] = React.useState(searchParams.get('filters') ? JSON.parse(searchParams.get('filters')) : {});

  // Restore state when returning from order view
  React.useEffect(() => {
    if (location.state?.from === '/returns' && location.state?.state) {
      const { page, pageSize, startDate, endDate, filters } = location.state.state;
      setPage(page);
      setPageSize(pageSize);
      setStartDate(moment(startDate));
      setEndDate(moment(endDate));
      setFilters(JSON.parse(filters));
      setSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
        startDate,
        endDate,
        filters
      });
    }
  }, [location.state]);

  useEffect(() => {
    const params = {
      startDate,
      endDate,
      page,
      limit: pageSize,
      isReturn: true,
      ...filters
    };
    getOrders(params);
  }, [page, pageSize, startDate, endDate, filters])

  const handleFilter = () => {
    setPage(1);
    setSearchParams({
      page: '1',
      pageSize: pageSize.toString(),
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      filters: JSON.stringify(filters)
    });
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
    setFilters(filters);
    setSearchParams({
      page: pagination.current.toString(),
      pageSize: pagination.pageSize.toString(),
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      filters: JSON.stringify(filters)
    });
  }

  const columns = [
    {
      title: 'Shopify Number',
      dataIndex: 'shopifyOrderNumber',
      filters: [
        ...new Map(
          orders?.docs
            ?.filter((order) => order.shopifyOrderNumber !== null).map((order) => [
              order.shopifyOrderNumber,
              { text: order.shopifyOrderNumber, value: order.shopifyOrderNumber },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.shopifyOrderNumber?.startsWith(value),
    },
    {
      title: 'Date Created',
      dataIndex: 'date',
      sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
    },
    {
      title: 'Date Delivered',
      dataIndex: 'dateDelivered',
      sorter: (a, b) => { return moment(a.dateDelivered).isBefore(b.dateDelivered) ? -1 : 1 },
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer',
      filters: orders?.docs?.filter((order) => order.fulfillment !== null).map((order) => {
        return {
          text: order.fulfillment?.customer,
          value: order.fulfillment?.customer
        }
      }),
      filterSearch: true,
      onFilter: (value, record) => record.customer.startsWith(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: "New",
          value: "New"
        },
        {
          text: "Preparing",
          value: "Preparing"
        },
        {
          text: "Prepared",
          value: "Prepared"
        },
        {
          text: "Out for Delivery",
          value: "Out for Delivery"
        },
        {
          text: "Delivered",
          value: "Delivered"
        },
        {
          text: "Returned",
          value: "Returned"
        },
        {
          text: "Returned To Chainz",
          value: "Returned to Chainz"
        },
        {
          text: "Cancelled",
          value: "Cancelled"
        },
        {
          text: "Sold Out",
          value: "Sold Out"
        },
        {
          text: "Invalid",
          value: "Invalid"
        }
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, { status }) => status.toUpperCase()
    },
    {
      title: 'Actions',
      render: (_, { _id, returnObj }) =>
        <Space>
          <Button 
            sx={{ ...main_button }} 
            onClick={() => {
              const currentState = {
                page,
                pageSize,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                filters: JSON.stringify(filters)
              };
              navigate(`/orders/${_id}`, { state: { from: '/returns', state: currentState } });
            }}
          >
            View Order
          </Button>
          <Button 
            sx={{ ...main_button }} 
            onClick={() => {
              const currentState = {
                page,
                pageSize,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                filters: JSON.stringify(filters)
              };
              navigate(`/orders/${returnObj.originalOrder}`, { state: { from: '/returns', state: currentState } });
            }}
          >
            View Original Order
          </Button>
        </Space>
    },
  ];

  const data = orders?.docs?.map((row) => {
    return {
      _id: row._id,
      date: moment(row.createdAt).format("lll"),
      dateFulfilled: row.preparedAt ? moment(row.preparedAt).format("lll") : null,
      dateDelivered: row.deliveredAt ? moment(row.deliveredAt).format("lll") : null,
      status: row.fulfillment ? row.status : "INVALID",
      shopifyOrderNumber: row.shopifyOrderNumber,
      customer: row.fulfillment?.customer,
      returnObj: row.return,
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", paddingX: 5 }}>
        <Grid container spacing={2} sx={{ ...centered_flex_box, mb: 1, overflowY: "auto" }}>
          <Grid item xs={6}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Total Returns</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  {!isLoading && <Ellipse size={210} stroke={15} text={orders?.total} />}
                  {isLoading && <Skeleton variant="circular" animation="wave" width={210} height={210} />}
                  <Typography color={"var(--primaryColor)"} fontSize={12}>{growthPercentage > 0 ? `+${growthPercentage}` : growthPercentage}% since last month</Typography>
                  <Button sx={{ ...main_button }} onClick={() => navigate("/external-return")}>Add External Return</Button>
                </Box>
              </Box>
            } />
          </Grid>
          <Grid item xs={6}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2} >Return Reasons</Typography>
                {isLoading && <Skeleton variant="rounded" animation="wave" width={700} height={265} />}
                {!isLoading && returnAnalysis && <BarChart
                  grid={{ horizontal: true }}
                  colors={["#F7463C", "#f7453c71", "#C83229", "#FF4237"]}
                  xAxis={[{ scaleType: 'band', data: ["Defective Item", "Wrong Item", "Customer Issue"] }]}
                  series={[{ data: [returnAnalysis?.totalDefective, returnAnalysis?.totalWarehouseMistake, returnAnalysis?.totalCustomerErrors] }]}
                  width={700}
                  height={265}
                />}
              </Box>
            } />
          </Grid>
        </Grid>
        <Button sx={{ ...main_button, my: 2 }} onClick={() => navigate("/external-order")}>Add External Order</Button>
        <NewCard sx={{ mb: 2 }} content={<Box sx={{ display: "flex", flexDirection: "row", px: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography>From</Typography>
              <MainInput
                margin="normal"
                fullWidth
                type="date"
                value={startDate.format("YYYY-MM-DD")}
                onChange={({ target }) => setStartDate(moment(target.value))}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography>To</Typography>
              <MainInput
                margin="normal"
                fullWidth
                type="date"
                value={endDate.format("YYYY-MM-DD")}
                onChange={({ target }) => setEndDate(moment(target.value))}
              />
            </Grid>
            <Grid item xs={2} sx={{ ...left_flex_box, alignItems: "end" }}>
              <Button sx={{ ...main_button, mb: 2 }} onClick={() => handleFilter()}>Filter</Button>
            </Grid>
          </Grid>
        </Box>} />
        <Box sx={{ 
          width: "100%", 
          overflowX: "auto",
          "& .ant-table-wrapper": {
            width: "100%"
          },
          "& .ant-table": {
            minWidth: 1200
          },
          "& .ant-table-cell": {
            whiteSpace: "nowrap"
          }
        }}>
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={{
              current: page,
              pageSize: pageSize,
              total: orders?.total,
              showSizeChanger: false,
              showQuickJumper: false,
              showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
            showSorterTooltip={{
              target: 'sorter-icon',
            }}
            scroll={{ x: 1200 }}
          />
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  orders: state?.records?.orders,
  returnAnalysis: state?.records?.returnAnalysis,
  growthPercentage: state?.records?.growthPercentage
});

const mapDispatchToProps = { getOrders };

export default connect(mapStateToProps, mapDispatchToProps)(Returns);
