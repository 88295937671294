import * as React from "react";
import {
  Typography,
  Box,
  IconButton,
  Grid,
  Button as MUIButton,
  Skeleton,
  MenuItem,
  Autocomplete
} from "@mui/material";
import { connect } from "react-redux";
import {
  NavLink,
  Navigate,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import {
  MainInput,
  centered_flex_box,
  left_flex_box,
  main_button,
  right_flex_box,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Button, Divider, Image, notification, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getCashCycles, getNextPayout, getTransactions } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { Download, DownloadingOutlined, DownloadOutlined } from "@mui/icons-material";
import moment from "moment";
import NewCard from "../app/components/NewCard";
import Ellipse from "../app/components/Ellipse";
import download from "downloadjs";
import ExcelJS from 'exceljs';

export const Transactions = ({
  getTransactions,
  user,
  transactions,
  getNextPayout,
  payoutDue,
  totalIncome,
  payouts,
  getCashCycles,
  cashCycles,
  isLoading
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = React.useState(moment(searchParams.get('startDate') || moment().startOf("month")))
  const [endDate, setEndDate] = React.useState(moment(searchParams.get('endDate') || moment().endOf("month")))
  const [cashoutId, setCashoutId] = React.useState(searchParams.get('cashoutId') || null)
  const [isDownloading, setIsDownloading] = React.useState(false)
  const [countdown, setCoundown] = React.useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })
  const [page, setPage] = React.useState(parseInt(searchParams.get('page')) || 1);
  const [pageSize, setPageSize] = React.useState(parseInt(searchParams.get('pageSize')) || 10);
  const [filters, setFilters] = React.useState(searchParams.get('filters') ? JSON.parse(searchParams.get('filters')) : {});

  // Restore state when returning from order view
  useEffect(() => {
    if (location.state?.from === '/transactions' && location.state?.state) {
      const { page, pageSize, startDate, endDate, cashoutId, filters } = location.state.state;
      setPage(page);
      setPageSize(pageSize);
      setStartDate(moment(startDate));
      setEndDate(moment(endDate));
      setCashoutId(cashoutId);
      setFilters(JSON.parse(filters));
      setSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
        startDate,
        endDate,
        cashoutId,
        filters
      });
    }
  }, [location.state]);

  useEffect(() => {
    const params = {
      startDate,
      endDate,
      page,
      limit: pageSize,
      cashoutId,
      ...filters
    };
    getTransactions(params);
    getNextPayout({});
    getCashCycles({});
  }, [page, pageSize, startDate, endDate, cashoutId, filters]);

  const handleFilter = () => {
    setPage(1);
    setSearchParams({
      page: '1',
      pageSize: pageSize.toString(),
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      cashoutId,
      filters: JSON.stringify(filters)
    });
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
    setFilters(filters);
    setSearchParams({
      page: pagination.current.toString(),
      pageSize: pagination.pageSize.toString(),
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      cashoutId,
      filters: JSON.stringify(filters)
    });
  }

  function getNextSundayOrWednesday() {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

    const daysUntilSunday = (7 - dayOfWeek) % 7; // If today is Sunday, it returns 0
    const daysUntilWednesday = (3 - dayOfWeek + 7) % 7; // If today is Wednesday, it returns 0

    // Choose the closest one
    const daysToAdd = daysUntilSunday && daysUntilSunday < daysUntilWednesday ? daysUntilSunday : daysUntilWednesday;

    const nextDate = new Date();
    nextDate.setDate(today.getDate() + daysToAdd);
    nextDate.setHours(0, 0, 0, 0); // Reset time to start of the day

    return nextDate;
  }

  function updateCountdown() {
    const nextDate = getNextSundayOrWednesday();
    const now = new Date();

    let timeDiff = nextDate - now; // Difference in milliseconds

    if (timeDiff < 0) return; // In case the event has already passed today

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    timeDiff %= (1000 * 60 * 60 * 24);

    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    timeDiff %= (1000 * 60 * 60);

    const minutes = Math.floor(timeDiff / (1000 * 60));
    timeDiff %= (1000 * 60);

    const seconds = Math.floor(timeDiff / 1000);

    setCoundown({ days, hours, minutes, seconds });
  }

  // Set up the countdown interval
  useEffect(() => {
    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const columns = [
    {
      title: 'Order ID',
      dataIndex: '_id',
    },
    {
      title: 'Shopify Number',
      dataIndex: 'shopifyOrderNumber',
      filters: transactions
        ? [...new Map(
            transactions
              .filter(({ order }) => order.shopifyOrderNumber !== null)
              .map(({ order }) => [
                order.shopifyOrderNumber,
                { text: order.shopifyOrderNumber, value: order.shopifyOrderNumber },
              ])
          ).values()]
        : [],
      filterSearch: true,
      onFilter: (value, record) => record.shopifyOrderNumber?.startsWith(value),
    },
    {
      title: 'Tracking ID',
      dataIndex: 'trackingId',
    },
    {
      title: 'Cashout ID',
      dataIndex: 'cashoutId',
    },
    {
      title: 'Status',
      dataIndex: ['order', 'status'],
      filters: [
        {
          text: "New",
          value: "New"
        },
        {
          text: "Preparing",
          value: "Preparing"
        },
        {
          text: "Prepared",
          value: "Prepared"
        },
        {
          text: "Out for Delivery",
          value: "Out for Delivery"
        },
        {
          text: "Delivered",
          value: "Delivered"
        },
        {
          text: "Cancelled",
          value: "Cancelled"
        },
        {
          text: "Sold Out",
          value: "Sold Out"
        },
        {
          text: "Invalid",
          value: "Invalid"
        }
      ],
      onFilter: (value, record) => record.order.status === value,
      render: (_, { order }) => order.status.toUpperCase()
    },
    {
      title: 'Actions',
      render: (_, { order }) => <Space>
        <Typography 
          sx={{ cursor: "pointer" }} 
          onClick={() => {
            const currentState = {
              page,
              pageSize,
              startDate: startDate.format('YYYY-MM-DD'),
              endDate: endDate.format('YYYY-MM-DD'),
              cashoutId,
              filters: JSON.stringify(filters)
            };
            navigate(`/orders/${order._id}`, { state: { from: '/transactions', state: currentState } });
          }}
        >
          View Order
        </Typography>
      </Space>
    },
  ];

  const data = transactions?.map((row) => ({
    _id: row.order._id,
    order: row.order,
    transactions: row.transactions,
    cashoutId: row.transactions[0].cashoutId,
    shopifyOrderNumber: row.order.shopifyOrderNumber,
    chainzFinanceStatus: row.order.chainzFinanceStatus,
    trackingId: row.order.shipbluTrackingId || row.order.bostaTrackingId,
  })) || [];

  const payoutColumns = [
    {
      title: 'Date Paid',
      dataIndex: 'date',
      sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (_, { value }) => `${value} EGP`,
      sorter: (a, b) => { return a.value - b.value },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        {
          text: "Service Fee",
          value: "Fee"
        },
        {
          text: "Payout",
          value: "Payout"
        }
      ],
      onFilter: (value, record) => record.type === value,
      render: (_, { type }) => type
    },
    {
      title: 'Service',
      dataIndex: 'service',
      render: (_, { service }) => service
    },
  ];

  const payoutData = payouts?.map((row) => ({
    _id: row._id,
    date: moment(row.createdAt).format("lll"),
    service: row.service,
    type: row.type,
    value: row.value
  })) || [];

  const handleExportToExcel = async () => {
    setIsDownloading(true)
    notification.info({ message: "Downloading Finance Export" })
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Finance Overview');
    const worksheet2 = workbook.addWorksheet('Completed Payouts');
    workbook.creator = 'Chainz';

    // Submissions by Country
    worksheet.columns = [
      { header: 'Order Tracking Id', key: 'trackingId' },
      { header: 'Cashout Date', key: 'chashoutDate' },
      { header: 'Cashout ID', key: 'chashoutId' },
      { header: 'Order Number', key: 'shopifyOrderNumber' },
      { header: 'Type', key: 'type' },
      { header: 'COD', key: 'cod' },
      { header: 'Shipping Fees', key: 'fees' },
      { header: 'Chainz Fees', key: 'chainzFees' },
      { header: 'Net Income', key: 'net' },
    ];

    worksheet2.columns = [
      { header: 'Payout Date', key: 'payoutDate' },
      { header: 'Payout Value', key: 'value' }
    ];

    data?.forEach((transactionData) => {
      if (transactionData && transactionData.transactions) {
        worksheet.addRow({
          "trackingId": transactionData.trackingId,
          "chashoutId": transactionData.cashoutId,
          "chashoutDate": moment(transactionData.transactions[0].createdAt).format("DD/MM/YYYY"),
          "shopifyOrderNumber": transactionData.shopifyOrderNumber,
          "type": transactionData.transactions.find(({ type }) => { return type === "ShippingFee" })?.service,
          "cod": transactionData.transactions.find(({ type }) => { return type === "COD" })?.value || 0,
          "fees": transactionData.transactions.find(({ type }) => { return type === "ShippingFee" })?.value || 0,
          "chainzFees": transactionData.transactions.find(({ type }) => { return type === "Fee" })?.value || 0,
          "net": (transactionData.transactions.find(({ type }) => { return type === "COD" })?.value || 0) - (transactionData.transactions.find(({ type }) => { return type === "ShippingFee" })?.value || 0) - (transactionData.transactions.find(({ type }) => { return type === "Fee" })?.value || 0)
        });
      }
    });

    payoutData?.forEach((transactionData) => {
      worksheet2.addRow({
        "payoutDate": transactionData.date,
        "value": transactionData.value
      });
    });

    worksheet.autoFilter = {
      from: 'A1',
      to: `I${data?.length + 1}`,
    };

    const buffer = await workbook.xlsx.writeBuffer();
    const mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    const fileName = `${user?.vendor.brandName} Finance Export - ${startDate.format("DD_MM_YYYY")} to ${endDate.format("DD_MM_YYYY")}.xlsx`
    const blob = new Blob([buffer], { type: mimeType })
    download(blob, fileName, mimeType);
    setIsDownloading(false)
  }

  const expandedRowRender = (items) => {
    const columns = [
      {
        title: 'Date Created',
        dataIndex: 'date',
        sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
      },
      {
        title: 'Value',
        dataIndex: 'value',
        render: (_, { value }) => `${(value).toFixed(2)} EGP`,
        sorter: (a, b) => { return a.value - b.value },
      },
      {
        title: 'Type',
        dataIndex: 'type',
        filters: [
          {
            text: "COD",
            value: "COD"
          },
          {
            text: "Service Fee",
            value: "Fee"
          },
          {
            text: "Shipping Fee",
            value: "ShippingFee"
          },
          {
            text: "Hold",
            value: "Hold"
          },
          {
            text: "Payout",
            value: "Payout"
          }
        ],
        onFilter: (value, record) => record.type === value,
        render: (_, { type }) => type
      },
      {
        title: 'Service',
        dataIndex: 'service',
        filters: [
          {
            text: "RTO",
            value: "RTO"
          },
          {
            text: "RTN",
            value: "RTN"
          },
          {
            text: "EXG",
            value: "EXG"
          },
          {
            text: "DLV",
            value: "DLV"
          },
          {
            text: "TRN",
            value: "TRN"
          },
          {
            text: "NONE",
            value: "NONE"
          }
        ],
        onFilter: (value, record) => record.service === value,
        render: (_, { service }) => service
      },
    ];

    const data = items?.map((row) => ({
      _id: row._id,
      date: moment(row.createdAt).format("lll"),
      value: row.value,
      type: row.type,
      service: row.service,
    })) || [];
    
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const nextPayoutDate = getNextSundayOrWednesday();

  return (
    <div>
      <Box sx={{ width: "100%", paddingX: 5 }}>
        <Grid container spacing={2} sx={{ ...centered_flex_box, mb: 1, overflowY: "auto" }}>
          <Grid item xs={4}>
            <NewCard sx={{ height: "35vh" }} content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Next Payout</Typography>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography>{moment(nextPayoutDate).format("ll")}</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ ...right_flex_box }}>
                    <Typography>{moment(nextPayoutDate).fromNow()}</Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container sx={{ mb: 2 }}>
                  <Grid item xs={8}>
                    <Typography>Timer</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ ...right_flex_box }}>
                    <Typography variant="caption">Cairo, Egypt</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                    <Typography fontSize={20} textAlign={"center"}>{countdown.days}</Typography>
                    <Typography variant="caption" textAlign={"center"}>Days</Typography>
                  </Grid>
                  <Grid item xs={4 / 3} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                    <Typography fontSize={20} textAlign={"center"}>:</Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                    <Typography fontSize={20} textAlign={"center"}>{countdown.hours}</Typography>
                    <Typography variant="caption" textAlign={"center"}>Hours</Typography>
                  </Grid>
                  <Grid item xs={4 / 3} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                    <Typography fontSize={20} textAlign={"center"}>:</Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                    <Typography fontSize={20} textAlign={"center"}>{countdown.minutes}</Typography>
                    <Typography variant="caption" textAlign={"center"}>Minutes</Typography>
                  </Grid>
                  <Grid item xs={4 / 3} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                    <Typography fontSize={20} textAlign={"center"}>:</Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                    <Typography fontSize={20} textAlign={"center"}>{countdown.seconds}</Typography>
                    <Typography variant="caption" textAlign={"center"}>Seconds</Typography>
                  </Grid>
                </Grid>
              </Box>
            } />
          </Grid>
          <Grid item xs={4}>
            <NewCard sx={{ height: "35vh" }} content={
              <Box>
                <Typography fontWeight={"bold"}>Payout Value</Typography>
                <Typography variant="caption" marginBottom={2}>Updated every Monday and Thursday</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column", height: "20vh" }}>
                  <NewCard sx={{ minWidth: "80%", backgroundColorNormal: "#313131 !important", backgroundColorHovered: "var(--primaryColor) !important" }} content={
                    <>
                      {!isLoading && <Typography fontWeight={"bold"} textAlign={"center"}>{parseFloat(payoutDue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} EGP</Typography>}
                      {isLoading && <Skeleton variant="rounded" animation="wave" width={250} height={25} />}
                    </>
                  } />
                  <Typography variant="caption">Scheduled for {moment(nextPayoutDate).format("ll")}</Typography>
                </Box>
              </Box>
            } />
          </Grid>
          <Grid item xs={4}>
            <NewCard sx={{ height: "35vh" }} content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Revenue Overview</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column", height: "20vh" }}>
                  <NewCard sx={{ minWidth: "80%", backgroundColorNormal: "#313131 !important", backgroundColorHovered: "var(--primaryColor) !important" }} content={
                    <>
                      {!isLoading && <Typography fontWeight={"bold"} textAlign={"center"}>{parseFloat(totalIncome).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} EGP</Typography>}
                      {isLoading && <Skeleton variant="rounded" animation="wave" width={250} height={25} />}
                    </>
                  } />
                  <Typography variant="caption">Total income since {moment(user?.createdAt).format("ll")}</Typography>
                  <NewCard sx={{ minWidth: "80%", mt: 2, backgroundColorNormal: "#313131 !important", backgroundColorHovered: "var(--primaryColor) !important" }} content={
                    <>
                      {!isLoading && payouts && <Typography fontWeight={"bold"} textAlign={"center"}>{(payouts.length > 0 ? parseFloat(payouts[0].value) : 0.00).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} EGP</Typography>}
                      {(isLoading || !payouts) && <Skeleton variant="rounded" animation="wave" width={250} height={25} />}
                    </>
                  } />
                  <Typography variant="caption">Latest Payout</Typography>
                </Box>
              </Box>
            } />
          </Grid>
        </Grid>

        <NewCard content={
          <Box sx={{ display: "flex", flexDirection: "row", px: 5 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>From</Typography>
                <MainInput
                  margin="normal"
                  fullWidth
                  type="date"
                  value={startDate.format("YYYY-MM-DD")}
                  onChange={({ target }) => setStartDate(moment(target.value))}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>To</Typography>
                <MainInput
                  margin="normal"
                  fullWidth
                  type="date"
                  value={endDate.format("YYYY-MM-DD")}
                  onChange={({ target }) => setEndDate(moment(target.value))}
                />
              </Grid>
              <Grid item xs={6} sx={{ ...left_flex_box, alignItems: "end" }}>
                <Autocomplete
                  options={cashCycles}
                  fullWidth
                  onChange={(_, value) => setCashoutId(value?.title)}
                  getOptionLabel={(cashCycle) => { return cashCycle.title }}
                  renderInput={(params) => <MainInput
                    {...params}
                    margin="normal"
                    fullWidth
                    label="Cashout ID"
                  />}
                />
              </Grid>
              <Grid item xs={2} sx={{ ...left_flex_box, alignItems: "end" }}>
                <MUIButton sx={{ ...main_button, mb: 2 }} onClick={handleFilter}>Filter</MUIButton>
              </Grid>
            </Grid>
          </Box>
        } />
        
        <Box sx={{ mb: 1, mt: 2, ...left_flex_box, flexDirection: "row" }}>
          <Typography variant='h5' sx={{ color: "var(--secColor4)" }}>All Order Transactions</Typography>
          <IconButton onClick={handleExportToExcel} disabled={isDownloading || !data} sx={{ color: "var(--secColor4)", mx: 2 }}>{isDownloading ? <DownloadingOutlined /> : <DownloadOutlined />}</IconButton>
        </Box>
        <Box sx={{ 
          width: "100%", 
          overflowX: "auto",
          "& .ant-table-wrapper": {
            width: "100%"
          },
          "& .ant-table": {
            minWidth: 1200
          },
          "& .ant-table-cell": {
            whiteSpace: "nowrap"
          }
        }}>
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={{
              current: page,
              pageSize: pageSize,
              total: transactions?.length,
              showSizeChanger: false,
              showQuickJumper: false,
              showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
            showSorterTooltip={{
              target: 'sorter-icon',
            }}
            rowKey={"_id"}
            expandable={{
              expandedRowRender: (record) => expandedRowRender(record.transactions)
            }}
            scroll={{ x: 1200 }}
          />
        </Box>
        <Typography variant='h5' sx={{ color: "var(--secColor4)", mt: 5 }}>Payouts</Typography>
        <Table
          columns={payoutColumns}
          dataSource={payoutData}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  transactions: state?.records?.transactions,
  payoutDue: state?.records?.payoutDue,
  totalIncome: state?.records?.totalIncome,
  cashCycles: state?.records?.cashCycles,
  payouts: state?.records?.payouts
});

const mapDispatchToProps = { getTransactions, getNextPayout, getCashCycles };

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
