import * as React from 'react';
import { Typography, Box, Container, Tooltip, Button, TextField, CssBaseline, Avatar, Menu, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, CardContent, Divider, IconButton, Skeleton } from '@mui/material';
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, centered_flex_box, left_flex_box, main_button, right_flex_box, sec_button } from '../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Image, Progress } from 'antd';
import { AdminPanelSettingsOutlined, ArrowForward, Dashboard, RequestPageOutlined, VisibilityOutlined } from '@mui/icons-material';
import { getDashboard } from '../app/store/actions/dataActions';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment/moment';
import DataCard from '../app/components/DataCard';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart, useDrawingArea } from '@mui/x-charts';
import NewCard from '../app/components/NewCard';

export const Home = ({ user, getDashboard, dashboard, history, isLoading }) => {

    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(moment().startOf("month"))
    const [endDate, setEndDate] = useState(moment().endOf("month"))
    const [target, setTarget] = useState(localStorage.getItem(`${user?._id}_target`) || 30000);
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    useEffect(() => {
        getDashboard({ startDate, endDate });
    }, [])

    const selectTarget = (target) => {
        setTarget(target);
        localStorage.setItem(`${user?._id}_target`, target);
    }

    function PieCenterLabel({ children }) {
        const { width, height, left, top } = useDrawingArea();
        return (
            <Typography x={left + width / 2} y={top + height / 2}>
                {children}
            </Typography>
        );
    }

    return (
        <Container sx={{ minWidth: isMobile ? "100% !important" : "90% !important" }}>
            <Grid container spacing={2}>
                <Grid container spacing={2} item xs={4}>
                    <Grid item xs={12}>
                        <NewCard content={
                            <Box>
                                <Typography marginBottom={2}>Welcome Back,</Typography>
                                <Typography fontSize={40} fontWeight={"bold"}>{user.name}</Typography>
                            </Box>
                        } />
                    </Grid>
                    <Grid item xs={12}>
                        <NewCard content={
                            <Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography marginBottom={2}>Sales Target Progress</Typography>
                                    <Typography variant="text" sx={{ cursor: "pointer", color: "var(--primaryColor)", textDecoration: "underline" }} marginBottom={2} onClick={handleClick}>Edit</Typography>
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={menuOpen}
                                    onClose={() => setMenuOpen(false)}
                                >
                                    <Box sx={{ backgroundColor: "var(--primaryColor)", maxHeight: "20vh", overflowY: "auto", paddingX: 2 }}>
                                        <MainInput
                                            margin="normal"
                                            type="number"
                                            label="Sales Target (EGP)"
                                            inputProps={{
                                                min: 0
                                            }}
                                            value={target}
                                            onChange={({ target }) => selectTarget(target.value)}
                                        />
                                    </Box>
                                </Menu>
                                <Tooltip title={`${parseFloat(dashboard?.cod?.totalIncome).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} EGP / ${target} EGP`}>
                                    <Progress strokeWidth={20} strokeColor={"var(--primaryColor)"} trailColor={"var(--primaryColorTranslucent)"} percent={parseFloat(dashboard?.cod?.totalIncome) / target * 100} showInfo={false} />
                                </Tooltip>
                                <Typography>{parseInt(target).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} EGP</Typography>
                            </Box>
                        } />
                    </Grid>
                    <Grid item xs={12}>
                        <NewCard content={
                            <Box>
                                <Typography marginBottom={2}>Net Income</Typography>
                                <Progress strokeWidth={20} strokeColor={"var(--primaryColor)"} trailColor={"var(--primaryColorTranslucent)"} percent={parseFloat(dashboard?.cod?.netIncome) / parseFloat(dashboard?.cod?.totalIncome) * 100} showInfo={false} />
                                <Typography>{parseFloat(dashboard?.cod?.netIncome).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} EGP</Typography>
                            </Box>
                        } />
                    </Grid>
                    <Grid item xs={12}>
                        <NewCard content={
                            <Box>
                                <Typography>Inventory Levels</Typography>
                                <Typography marginBottom={2} fontSize={12}>Stock Levels /Month</Typography>
                                {history?.length === 0 && <Typography textAlign={"center"}>Requires Minimum 1 Month Data</Typography>}
                                {!isLoading && history?.length > 0 && <BarChart
                                    grid={{ vertical: true }}
                                    slotProps={{
                                        bar: {
                                            sx: {
                                                borderRadius: 20,
                                            },
                                        },
                                    }}
                                    colors={["#F7463C", "#f7453c71", "#C83229", "#FF4237"]}
                                    dataset={history?.map(({ stockLevel, date }) => ({ month: moment(date).format("MMM"), stockLevel }))}
                                    yAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                                    series={[{ dataKey: 'stockLevel' }]}
                                    layout="horizontal"
                                    width={400}
                                    height={600}
                                />}
                                {isLoading && <Skeleton variant="rounded" animation="wave" width={400} height={600} />}
                            </Box>
                        } />
                    </Grid>
                </Grid>
                <Grid container spacing={2} item xs={8}>
                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={4}>
                            <NewCard content={
                                <Box>
                                    <Typography marginBottom={2}>Total Stocks</Typography>
                                    {!isLoading && <Typography>{parseInt(dashboard?.stocks?.totalStockItems)} Items</Typography>}
                                    {isLoading && <Skeleton variant="rounded" animation="wave" width={250} height={25} />}
                                </Box>
                            } />
                        </Grid>
                        <Grid item xs={4}>
                            <NewCard content={
                                <Box>
                                    <Typography marginBottom={2}>Pending Orders</Typography>
                                    {!isLoading && <Typography>{dashboard?.orders?.totalEnRoute + dashboard?.orders?.totalFulfilled + dashboard?.orders?.totalOther} Orders</Typography>}
                                    {isLoading && <Skeleton variant="rounded" animation="wave" width={250} height={25} />}
                                </Box>
                            } />
                        </Grid>
                        <Grid item xs={4}>
                            <NewCard content={
                                <Box>
                                    <Typography marginBottom={2}>Returned Orders</Typography>
                                    {!isLoading && <Typography>{dashboard?.returnAnalysis?.totalReturns} Orders</Typography>}
                                    {isLoading && <Skeleton variant="rounded" animation="wave" width={250} height={25} />}
                                </Box>
                            } />
                        </Grid>
                        <Grid item xs={6}>
                            <NewCard content={
                                <Box>
                                    <Typography>Returns Analysis</Typography>
                                    <Typography marginBottom={2} fontSize={12}>Return reasons /Order</Typography>
                                    {!isLoading && dashboard?.returnAnalysis && <BarChart
                                        grid={{ horizontal: true }}
                                        colors={["#F7463C", "#f7453c71", "#C83229", "#FF4237"]}
                                        dataset={history?.map(({ stockLevel, date }) => ({ month: moment(date).format("MMM"), stockLevel }))}
                                        xAxis={[{ scaleType: 'band', data: ["Defective Item", "Wrong Item", "Customer Issue"] }]}
                                        series={[{ data: [dashboard?.returnAnalysis?.totalDefective, dashboard?.returnAnalysis?.totalWarehouseMistake, dashboard?.returnAnalysis?.totalCustomerErrors] }]}
                                        width={400}
                                        height={400}
                                    />}
                                    {isLoading && <Skeleton variant="rounded" animation="wave" width={400} height={400} />}
                                </Box>
                            } />
                        </Grid>
                        <Grid item xs={6}>
                            <NewCard content={
                                <Box>
                                    <Typography>Fulfillment Trends</Typography>
                                    <Typography marginBottom={2} fontSize={12}>Fulfilled Orders /Month</Typography>
                                    {history?.length === 0 && <Typography textAlign={"center"}>Requires Minimum 1 Month Data</Typography>}
                                    {!isLoading && history?.length > 0 && <LineChart
                                        colors={["#F7463C", "#f7453c71", "#C83229", "#FF4237"]}
                                        grid={{ horizontal: true }}
                                        xAxis={[{ scaleType: 'point', data: history?.map(({ date }) => moment(date).format("MMM")) }]}
                                        series={[
                                            {
                                                data: history?.map(({ orders }) => orders),
                                            },
                                        ]}
                                        width={400}
                                        height={400}
                                    />}
                                    {isLoading && <Skeleton variant="rounded" animation="wave" width={400} height={400} />}
                                </Box>
                            } />
                        </Grid>
                        <Grid item xs={6}>
                            <NewCard content={
                                <Box>
                                    <Typography>Orders Fulfillment Status</Typography>
                                    <Typography marginBottom={2} fontSize={12}>{dashboard?.orders.totalOrders} Orders</Typography>
                                    <Box sx={{ marginLeft: 7 }}>
                                        {!isLoading && <PieChart
                                            height={400}
                                            width={400}
                                            colors={["#F7463C", "#f7453c71", "#C83229", "#FF4237"]}
                                            slotProps={{ legend: { hidden: true } }}
                                            series={[
                                                {
                                                    data: [
                                                        { id: 0, value: parseInt(dashboard?.orders.totalDelivered), label: 'Delivered Orders' },
                                                        { id: 1, value: parseInt(dashboard?.orders.totalFulfilled), label: 'Fulfilled Orders' },
                                                        { id: 3, value: parseInt(dashboard?.orders.totalSoldOutOrders), label: 'Sold Out Orders' },
                                                        { id: 4, value: parseInt(dashboard?.orders.totalEnRoute), label: 'Orders En Route' },
                                                        { id: 5, value: parseInt(dashboard?.orders.totalCancelled), label: 'Cancelled Orders' },
                                                        { id: 6, value: parseInt(dashboard?.orders.totalReturned), label: 'Returned Orders' },
                                                        { id: 7, value: parseInt(dashboard?.orders.totalOther), label: 'Other Orders' },
                                                    ],
                                                },
                                            ]}
                                        />}
                                    </Box>
                                    {isLoading && <Skeleton variant="circular" animation="wave" width={400} height={400} />}
                                </Box>
                            } />
                        </Grid>
                        <Grid item xs={6}>
                            <NewCard content={
                                <Box>
                                    <Typography>Sales Trends</Typography>
                                    <Typography marginBottom={2} fontSize={12}>COD /Month</Typography>
                                    {history?.length === 0 && <Typography textAlign={"center"}>Requires Minimum 1 Month Data</Typography>}
                                    {!isLoading && history?.length > 0 && <LineChart
                                        grid={{ horizontal: true }}
                                        colors={["#F7463C", "#f7453c71", "#C83229", "#FF4237"]}
                                        xAxis={[{ scaleType: 'point', data: history?.map(({ date }) => moment(date).format("MMM")) }]}
                                        series={[
                                            {
                                                curve: "linear", data: history?.map(({ sales }) => sales),
                                            },
                                        ]}
                                        width={400}
                                        height={400}
                                    />}
                                    {isLoading && <Skeleton variant="rounded" animation="wave" width={400} height={400} />}
                                </Box>
                            } />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading,
    history: state?.records?.history,
    dashboard: state?.records?.dashboard
});

const mapDispatchToProps = { getDashboard };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
