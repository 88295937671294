import * as React from "react";
import {
  Typography,
  Box,
  Button,
  Link,
  Grid,
  Skeleton,
} from "@mui/material";
import { connect } from "react-redux";
import {
  Navigate,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import {
  centered_flex_box,
  left_flex_box,
  main_button,
  MainInput,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getOrders } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";
import NewCard from "../app/components/NewCard";
import { Gauge } from "@mui/x-charts";
import Ellipse from "../app/components/Ellipse";

export const Orders = ({
  getOrders,
  user,
  orders,
  growthPercentage,
  isLoading
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = React.useState(null);
  const [terms, setTerms] = React.useState(false);
  const [page, setPage] = React.useState(parseInt(searchParams.get('page')) || 1);
  const [pageSize, setPageSize] = React.useState(parseInt(searchParams.get('pageSize')) || 10);
  const [startDate, setStartDate] = React.useState(searchParams.get('startDate') ? moment(searchParams.get('startDate')) : null)
  const [endDate, setEndDate] = React.useState(searchParams.get('endDate') ? moment(searchParams.get('endDate')) : null)
  const [filters, setFilters] = React.useState(searchParams.get('filters') ? JSON.parse(searchParams.get('filters')) : {});

  // Restore state when returning from order view
  React.useEffect(() => {
    if (location.state?.from === '/orders' && location.state?.state) {
      const { page, pageSize, startDate, endDate, filters } = location.state.state;
      setPage(page);
      setPageSize(pageSize);
      setStartDate(startDate ? moment(startDate) : null);
      setEndDate(endDate ? moment(endDate) : null);
      setFilters(JSON.parse(filters));
      setSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
        startDate,
        endDate,
        filters
      });
    }
  }, [location.state]);

  useEffect(() => {
    const params = {
      startDate,
      endDate,
      page,
      limit: pageSize,
      ...filters
    };
    console.log(params);
    getOrders(params);
  }, [page, pageSize, startDate, endDate, filters])

  const handleFilter = () => {
    setPage(1);
    setSearchParams({
      page: '1',
      pageSize: pageSize.toString(),
      startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
      endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
      filters: JSON.stringify(filters)
    });
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
    setFilters(filters);
    setSearchParams({
      page: pagination.current.toString(),
      pageSize: pagination.pageSize.toString(),
      startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
      endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
      filters: JSON.stringify(filters)
    });
  }

  const columns = [
    {
      title: 'Shopify Number',
      dataIndex: 'shopifyOrderNumber',
    },
    {
      title: 'Tracking ID',
      dataIndex: 'trackingId',
    },
    {
      title: 'Date Ordered',
      dataIndex: 'date',
      sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
    },
    {
      title: 'Date Fulfilled',
      dataIndex: 'dateFulfilled',
      sorter: (a, b) => { return moment(a.dateFulfilled).isBefore(b.dateFulfilled) ? -1 : 1 },
    },
    {
      title: 'Date Delivered',
      dataIndex: 'dateDelivered',
      sorter: (a, b) => { return moment(a.dateDelivered).isBefore(b.dateDelivered) ? -1 : 1 },
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer',
    },
    {
      title: 'Order Price',
      dataIndex: 'price',
      render: (_, { price }) => `${price} EGP`,
      sorter: (a, b) => { return a.price - b.price },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: "New",
          value: "New"
        },
        {
          text: "Preparing",
          value: "Preparing"
        },
        {
          text: "Prepared",
          value: "Prepared"
        },
        {
          text: "Out for Delivery",
          value: "In Transit"
        },
        {
          text: "Delivered",
          value: "Delivered"
        },
        {
          text: "Cancelled",
          value: "Cancelled"
        },
        {
          text: "Returned",
          value: "Returned"
        },
        {
          text: "Returned To Chainz",
          value: "Returned to Chainz"
        },
        {
          text: "Sold Out",
          value: "Sold Out"
        },
        {
          text: "Invalid",
          value: "Invalid"
        }
      ],
      onFilter: (value, record) => value === "In Transit" ? ["In Transit", "Out for Delivery", "Delivery Attempted: 1", "Delivery Attempted: 2"].includes(record.status) : record.status === value,
      render: (_, { status }) => status.toUpperCase()
    },
    {
      title: 'Payment Status',
      dataIndex: 'chainzFinanceStatus',
      filters: [
        {
          text: "Pending",
          value: "Pending"
        },
        {
          text: "Paid",
          value: "Paid"
        },
      ],
      onFilter: (value, record) => record.chainzFinanceStatus === value,
      render: (_, { chainzFinanceStatus }) => chainzFinanceStatus.toUpperCase()
    },
    {
      title: 'Actions',
      render: (_, { _id }) => <Space>
        <Button
          sx={{ ...main_button }}
          onClick={() => {
            const currentState = {
              page,
              pageSize,
              startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
              endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
              filters: JSON.stringify(filters)
            };
            navigate(`/orders/${_id}`, { state: { from: '/orders', state: currentState } });
          }}
        >
          View Order
        </Button>
      </Space>
    },
  ];

  const data = orders?.docs?.map((row) => {
    return {
      _id: row._id,
      shopifyOrderNumber: row.shopifyOrderNumber,
      chainzFinanceStatus: row.chainzFinanceStatus,
      trackingId: row.shipbluTrackingId || row.bostaTrackingId,
      date: moment(row.createdAt).format("lll"),
      dateFulfilled: row.preparedAt ? moment(row.preparedAt).format("lll") : null,
      dateDelivered: row.deliveredAt ? moment(row.deliveredAt).format("lll") : null,
      status: row.fulfillment ? row.status : row.status === "Cancelled" ? "Cancelled" : "INVALID",
      customer: row.fulfillment?.customer,
      price: row.fulfillment?.price,
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", paddingX: 5 }}>
        <Grid container spacing={2} sx={{ ...centered_flex_box, mb: 1, overflowY: "auto" }}>
          <Grid item xs={12 / 5}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Total Orders</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  {!isLoading && <Ellipse text={orders?.total} />}
                  {isLoading && <Skeleton variant="circular" animation="wave" width={150} height={150} />}
                  <Typography color={"var(--primaryColor)"} fontSize={12}>{growthPercentage > 0 ? `+${growthPercentage}` : growthPercentage}% since last month</Typography>
                </Box>
              </Box>
            } />
          </Grid>
          <Grid item xs={12 / 5}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Delivering Orders</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  {!isLoading && <Ellipse text={orders?.docs.filter(order => !["New", "Sold Out", "Delivered", "Returned", "Returned to Chainz", "Cancelled"].includes(order.status)).length} />}
                  {isLoading && <Skeleton variant="circular" animation="wave" width={150} height={150} />}
                  <Typography color={"var(--primaryColor)"} fontSize={12}>{(orders?.docs.filter(order => !["New", "Sold Out", "Delivered", "Returned", "Returned to Chainz", "Cancelled"].includes(order.status)).length / orders?.total * 100).toFixed(1)}% of total orders are in transit</Typography>
                </Box>
              </Box>
            } />
          </Grid>
          <Grid item xs={12 / 5}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Delivered Orders</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  {!isLoading && <Ellipse text={orders?.docs.filter(order => ["Delivered"].includes(order.status)).length} />}
                  {isLoading && <Skeleton variant="circular" animation="wave" width={150} height={150} />}
                  <Typography color={"var(--primaryColor)"} fontSize={12}>{(orders?.docs.filter(order => ["Delivered"].includes(order.status)).length / (orders?.docs.filter(order => ["Delivered", "Returned", "Returned to Chainz"].includes(order.status)).length) * 100).toFixed(1)}% of sent orders are delivered</Typography>
                </Box>
              </Box>
            } />
          </Grid>
          <Grid item xs={12 / 5}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Returned Orders</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  {!isLoading && <Ellipse text={orders?.docs.filter(order => ["Returned", "Returned to Chainz"].includes(order.status)).length} />}
                  {isLoading && <Skeleton variant="circular" animation="wave" width={150} height={150} />}
                  <Typography color={"var(--primaryColor)"} fontSize={12}>{(orders?.docs.filter(order => ["Returned", "Returned to Chainz"].includes(order.status)).length / (orders?.docs.filter(order => ["Delivered", "Returned", "Returned to Chainz"].includes(order.status)).length) * 100).toFixed(1)}% of sent orders are returned</Typography>
                </Box>
              </Box>
            } />
          </Grid>
          <Grid item xs={12 / 5}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Cancelled Orders</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  {!isLoading && <Ellipse text={orders?.docs.filter(order => ["Cancelled"].includes(order.status)).length} />}
                  {isLoading && <Skeleton variant="circular" animation="wave" width={150} height={150} />}
                  <Typography color={"var(--primaryColor)"} fontSize={12}>{(orders?.docs.filter(order => ["Cancelled"].includes(order.status)).length / orders?.total * 100).toFixed(1)}% of total orders are cancelled</Typography>
                </Box>
              </Box>
            } />
          </Grid>
        </Grid>
        <Button sx={{ ...main_button, my: 2 }} onClick={() => navigate("/external-order")}>Add External Order</Button>
        <NewCard sx={{ mb: 2 }} content={<Box sx={{ display: "flex", flexDirection: "row", px: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography>From</Typography>
              <MainInput
                margin="normal"
                fullWidth
                type="date"
                value={startDate ? startDate.format("YYYY-MM-DD") : null}
                onChange={({ target }) => setStartDate(moment(target.value))}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography>To</Typography>
              <MainInput
                margin="normal"
                fullWidth
                type="date"
                value={endDate ? endDate.format("YYYY-MM-DD") : null}
                onChange={({ target }) => setEndDate(moment(target.value))}
              />
            </Grid>
            <Grid item xs={2} sx={{ ...left_flex_box, alignItems: "end" }}>
              <Button sx={{ ...main_button, mb: 2 }} onClick={() => handleFilter()}>Filter</Button>
            </Grid>
          </Grid>
        </Box>} />
        <Box sx={{
          width: "100%",
          overflowX: "auto",
          "& .ant-table-wrapper": {
            width: "100%"
          },
          "& .ant-table": {
            minWidth: 1200
          },
          "& .ant-table-cell": {
            whiteSpace: "nowrap"
          }
        }}>
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={{
              current: page,
              pageSize: pageSize,
              total: orders?.total,
              showSizeChanger: false,
              showQuickJumper: false,
              showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
            showSorterTooltip={{
              target: 'sorter-icon',
            }}
            scroll={{ x: 1200 }}
          />
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  orders: state?.records?.orders,
  growthPercentage: state?.records?.growthPercentage
});

const mapDispatchToProps = { getOrders };

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
