import * as React from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
} from "@mui/material";
import { connect } from "react-redux";
import {
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  MainInput,
  centered_flex_box,
  left_flex_box,
  main_button,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getOrders, addExchange, getProducts } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";
import { useState } from "react";

export const ReturnOrder = ({
  getOrders,
  user,
  order,
  products,
  addExchange,
  getProducts,
  isLoading
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [incomingItems, setIncomingItems] = useState([]);
  const [outgoingItems, setOutgoingItems] = useState([]);
  const [refundAmount, setRefundAmount] = useState(0);

  useEffect(() => {
    getOrders({ orderId: id })
  }, [id])

  useEffect(() => {
    getProducts({ page: 1, limit: 9999990 })
  }, [])

  const handleSubmit = () => {
    console.log({orderId: id, incomingItems, outgoingItems, refundAmount})
    addExchange({ orderId: id, incomingItems, outgoingItems, refundAmount }, navigate)
  }

  const returnColumns = [
    {
      title: 'SKU',
      dataIndex: 'externalId',
      filters: [
        ...new Map(
          order?.orderItems
          ?.map(({ stockItem }) => [
              stockItem.product.sku, // Key to ensure uniqueness
              { text: stockItem.product.sku, value: stockItem.product.sku },
          ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.externalId?.startsWith(value),
    },
    // {
    //   title: 'Name',
    //   dataIndex: 'name',
    // },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (text) => `${text} EGP`,
    },
    {
      title: 'Size',
      dataIndex: 'size',
      filters: [
          ...new Map(
            order?.orderItems
            ?.map(({ stockItem }) => [
                stockItem.product.size, // Key to ensure uniqueness
                { text: stockItem.product.size, value: stockItem.product.size },
            ])
          ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.size?.startsWith(value),
    },
    {
      title: 'Ordered',
      dataIndex: 'quantityOrdered',
      sorter: (a, b) => a.quantityOrdered - b.quantityOrdered,
    },
    {
      title: 'QTY to return',
      render: (_, { _id, quantityOrdered }) => <Space>
        <MainInput
          margin="normal"
          type="number"
          fullWidth
          id={_id}
          disabled={incomingItems.filter((item) => item._id === _id).length === 0}
          inputProps={{
            min: incomingItems.filter((item) => item._id === _id).length > 0 ? 1 : 0,
            max: quantityOrdered
          }}
          value={incomingItems.filter((item) => item._id === _id).length > 0 ? incomingItems.filter((item) => item._id === _id)[0].quantity : 0}
          onChange={({ target }) => onQuantityChange(_id, target.value)}
        />
      </Space>
    },
  ];

  const returnData = order?.orderItems?.map((row) => {
    return {
      _id: row._id,
      key: row._id,
      externalId: row.stockItem.product.sku || row.stockItem.product.externalId,
      price: row.stockItem.product.price,
      image: row.stockItem.product.image,
      name: row.stockItem.product.name,
      size: row.stockItem.product.size,
      color: row.stockItem.product.color,
      weight: row.stockItem.product.weight,
      quantityOrdered: row.quantityOrdered
    }
  })

  const outgoingColumns = [
      {
          title: 'SKU',
          dataIndex: 'externalId',
          filters: [
            ...new Map(
              products?.docs
              ?.map((product) => [
                  product.sku, // Key to ensure uniqueness
                  { text: product.sku, value: product.sku },
              ])
            ).values(),
          ],
          filterSearch: true,
          onFilter: (value, record) => record.externalId?.startsWith(value),
      },
      // {
      //     title: 'Name',
      //     dataIndex: 'name',
      //     filters: products?.docs?.map((product) => {
      //         return {
      //             text: product.name,
      //             value: product.name
      //         }
      //     }),
      //     filterSearch: true,
      //     onFilter: (value, record) => record.name.startsWith(value),
      //     defaultSortOrder: 'ascend',
      //     sorter: (a, b) => a.name - b.name,
      // },
      {
          title: 'Size',
          dataIndex: 'size',
          filters: [
              ...new Map(
                  products?.docs
                      ?.filter((product) => { return product.size }).map((product) => [
                          product.size, // Key to ensure uniqueness
                          { text: product.size, value: product.size },
                      ])
              ).values(),
          ],
          filterSearch: true,
          onFilter: (value, record) => record.size?.startsWith(value),
      },
      {
          title: 'Price',
          dataIndex: 'price',
          sorter: (a, b) => a.price - b.price,
          render: (_, { price }) => `${price} EGP`
      },
      {
          title: 'QTY on Hand',
          dataIndex: 'amount',
          sorter: (a, b) => a.amount - b.amount,
      },
      {
        title: 'QTY to send',
        render: (_, { _id }) => <Space>
          <MainInput
            margin="normal"
            type="number"
            fullWidth
            id={_id}
            disabled={outgoingItems.filter((item) => item._id === _id).length === 0}
            inputProps={{
              min: outgoingItems.filter((item) => item._id === _id).length > 0 ? 1 : 0
            }}
            value={outgoingItems.filter((item) => item._id === _id).length > 0 ? outgoingItems.filter((item) => item._id === _id)[0].quantity : 0}
            onChange={({ target }) => onOutgoingQuantityChange(_id, target.value)}
          />
        </Space>
      },
  ];

  const outgoingData = products?.docs?.map((row) => {
      return {
          _id: row._id,
          key: row._id,
          externalId: row.sku || row.externalId,
          image: row.image,
          name: row.name,
          size: row.size,
          color: row.color,
          weight: row.weight,
          price: row.price,
          amount: row.stocks?.reduce((a, b) => a + b.amount, 0),
      }
  })

  const onQuantityChange = (_id, newQuantity) => {
    const newItems = incomingItems.map((item) => {
      if (item._id === _id) {
        item.quantity = newQuantity;
      }
      return item
    });
    setIncomingItems(newItems);
  };

  const onOutgoingQuantityChange = (_id, newQuantity) => {
    const newItems = outgoingItems.map((item) => {
      if (item._id === _id) {
        item.quantity = newQuantity;
      }
      return item
    });
    setOutgoingItems(newItems);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setIncomingItems(newSelectedRowKeys.map((key) => { return { _id: key, quantity: 1 } }));
  };

  const rowSelection = {
    incomingItems,
    onChange: onSelectChange,
  };

  const onOutgoingSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setOutgoingItems(newSelectedRowKeys.map((key) => { return { _id: key, quantity: 1 } }));
  };

  const outgoingRowSelection = {
    outgoingItems,
    onChange: onOutgoingSelectChange,
  };

  const hasSelected = incomingItems.length > 0 && outgoingItems.length > 0

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor4)" }}>Order {order?.shopifyOrderNumber || id}</Typography>
          {/* <IconButton onClick={() => navigate("/ims/admin/products/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton> */}
        </Box>
        <Box sx={{}}>
          <Box sx={{ ...left_flex_box }}>
            <Space>
              <MainInput
                margin="normal"
                type="number"
                label="Refund Amount (Negative if COD)"
                disabled={!hasSelected}
                value={refundAmount}
                onChange={({ target }) => setRefundAmount(target.value)}
              />
              <Button sx={{...main_button}} onClick={handleSubmit} disabled={!hasSelected} loading={isLoading}>
                Send Exchange
              </Button>
              <Typography sx={{ ml: 1 }}>{hasSelected ? `Exchanging ${outgoingItems.length} items` : null}</Typography>
            </Space>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography textAlign={"center"}>Incoming Items</Typography>
              <Table
                columns={returnColumns}
                dataSource={returnData}
                showSorterTooltip={{
                  target: 'sorter-icon',
                }}
                rowSelection={rowSelection}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={"center"}>Outgoing Items</Typography>
              <Table
                columns={outgoingColumns}
                dataSource={outgoingData}
                showSorterTooltip={{
                  target: 'sorter-icon',
                }}
                rowSelection={outgoingRowSelection}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  products: state?.records?.products,
  token: state?.auth?.token,
  order: state?.records?.order
});

const mapDispatchToProps = { getOrders, getProducts, addExchange };

export default connect(mapStateToProps, mapDispatchToProps)(ReturnOrder);
