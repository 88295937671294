import * as React from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
  MenuItem,
} from "@mui/material";
import { connect } from "react-redux";
import {
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  MainInput,
  centered_flex_box,
  left_flex_box,
  main_button,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, notification, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { addOrderScratch, getProducts } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";
import { useState } from "react";
import cities from "../constants/cities.json";
import { render } from "@testing-library/react";

export const ScratchOrder = ({
  user,
  order,
  products,
  addOrderScratch,
  getProducts,
  isLoading
}) => {
  const navigate = useNavigate();
  const [outgoingItems, setOutgoingItems] = useState([]);
  const [price, setPrice] = useState(0);
  const [formData, setFormData] = useState({
    customer: {
      first_name: "",
      last_name: "",
    },
    phone: "",
    shipping_address: {
      address1: "",
      address2: "",
      province: "Cairo",
    },
    shopifyOrderNumber: ""
  });

  const canSubmit = () => {
    return formData.customer.first_name && formData.customer.last_name && formData.phone && formData.shipping_address.address1 && formData.shipping_address.province && outgoingItems.length > 0
  }

  useEffect(() => {
    getProducts({ page: 1, limit: 9999990 })
  }, [])

  const handleSubmit = () => {
    if(!canSubmit()) notification.error({ message: "Please fill all required fields" });
    console.log({ orderItems: outgoingItems, price, ...formData })
    addOrderScratch({ orderItems: outgoingItems, price, ...formData }, navigate)
  }

  const returnColumns = [
    {
      title: 'SKU',
      dataIndex: 'externalId',
      filters: [
        ...new Map(
          products?.docs
            ?.map((product) => [
              product.sku, // Key to ensure uniqueness
              { text: product.sku, value: product.sku },
            ])
        ).values(),
      ].sort(),
      filterSearch: true,
      onFilter: (value, record) => record.externalId?.startsWith(value),
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Image',
      dataIndex: "image",
      render: (text) => <Image src={text} width={50} height={50} />
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (text) => `${text} EGP`,
    },
    {
      title: 'Size',
      dataIndex: 'size',
      filters: [
        ...new Map(
          products?.docs
            ?.map((product) => [
              product.size, // Key to ensure uniqueness
              { text: product.size, value: product.size },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.size?.startsWith(value),
    },
    {
      title: 'QTY to send',
      render: (_, { _id }) => <Space>
        <MainInput
          margin="normal"
          type="number"
          fullWidth
          id={_id}
          disabled={outgoingItems.filter((item) => item._id === _id).length === 0}
          inputProps={{
            min: outgoingItems.filter((item) => item._id === _id).length > 0 ? 1 : 0
          }}
          value={outgoingItems.filter((item) => item._id === _id).length > 0 ? outgoingItems.filter((item) => item._id === _id)[0].quantity : 0}
          onChange={({ target }) => onQuantityChange(_id, target.value)}
        />
      </Space>
    },
  ];

  const returnData = products?.docs?.map((row) => {
    return {
      _id: row._id,
      key: row._id,
      externalId: row.sku || row.externalId,
      price: row.price,
      image: row.image,
      name: row.name,
      size: row.size,
      color: row.color,
      weight: row.weight,
    }
  })

  const onQuantityChange = (_id, newQuantity) => {
    const newItems = outgoingItems.map((item) => {
      if (item._id === _id) {
        item.quantity = newQuantity;
      }
      return item
    });
    setOutgoingItems(newItems);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setOutgoingItems(newSelectedRowKeys.map((key) => { return { _id: key, quantity: 1 } }));
  };

  const rowSelection = {
    outgoingItems,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor4)" }}>Add External Order</Typography>
          {/* <IconButton onClick={() => navigate("/ims/admin/products/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton> */}
        </Box>
        <Box sx={{}}>
          <Box sx={{ ...left_flex_box }}>
            <Grid container sx={{}}>
              <Grid item xs={12}>
                <Typography variant='h6'>Customer Information</Typography>
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={4}>
                    <MainInput
                      margin="normal"
                      label="First Name"
                      required
                      value={formData.customer.first_name}
                      fullWidth
                      onChange={({ target }) => setFormData({ ...formData, customer: { ...formData.customer, first_name: target.value } })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MainInput
                      margin="normal"
                      label="Last Name"
                      required
                      value={formData.customer.last_name}
                      fullWidth
                      onChange={({ target }) => setFormData({ ...formData, customer: { ...formData.customer, last_name: target.value } })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPhoneNumber
                      sx={{
                        "& label": {
                          color: "var(--secColor4) !important",
                        },
                        "& label.Mui-focused": {
                          color: "var(--secColor4) !important",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "green",
                        },
                        "& .MuiInputBase-input": {
                          outerHeight: 500,
                          color: "var(--secColor4)",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--secColor4)",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "var(--terColor)",
                          },

                          borderRadius: 0,
                        }
                      }}
                      margin="normal"
                      fullWidth
                      required
                      label={"Phone Number"}
                      variant='outlined'
                      onlyCountries={['eg']}
                      defaultCountry={'eg'}
                      countryCodeEditable={false}
                      inputProps={{
                        minLength: 13
                      }}
                      disableAreaCodes
                      value={formData.phone}
                      onChange={(value) => setFormData({ ...formData, phone: value })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>Shipping Information</Typography>
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={5}>
                    <MainInput
                      margin="normal"
                      label="Address Line 1"
                      required
                      value={formData.shipping_address.address1}
                      fullWidth
                      onChange={({ target }) => setFormData({ ...formData, shipping_address: { ...formData.shipping_address, address1: target.value } })}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <MainInput
                      margin="normal"
                      label="Address Line 2"
                      value={formData.shipping_address.address2}
                      fullWidth
                      onChange={({ target }) => setFormData({ ...formData, shipping_address: { ...formData.shipping_address, address2: target.value } })}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MainInput
                      margin="normal"
                      label="Province"
                      required
                      value={formData.shipping_address.province}
                      fullWidth
                      select
                      onChange={({ target }) => setFormData({ ...formData, shipping_address: { ...formData.shipping_address, province: target.value } })}
                    >
                      {cities.map((city) => <MenuItem key={city} value={city}>{city}</MenuItem>)}
                    </MainInput>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>Order Information</Typography>
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={4}>
                    <MainInput
                      margin="normal"
                      label="Order Number"
                      placeholder="#XXXX"
                      value={formData.shopifyOrderNumber}
                      fullWidth
                      onChange={({ target }) => setFormData({ ...formData, shopifyOrderNumber: target.value })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MainInput
                      margin="normal"
                      type="number"
                      label="Price"
                      value={price}
                      fullWidth
                      onChange={({ target }) => setPrice(target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button sx={{ ...main_button }} onClick={handleSubmit} loading={isLoading}>
                  Send Order
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography textAlign={"center"}>Order Items</Typography>
              <Table
                columns={returnColumns}
                dataSource={returnData}
                showSorterTooltip={{
                  target: 'sorter-icon',
                }}
                loading={isLoading}
                rowSelection={rowSelection}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  products: state?.records?.products,
  token: state?.auth?.token,
  order: state?.records?.order
});

const mapDispatchToProps = { getProducts, addOrderScratch };

export default connect(mapStateToProps, mapDispatchToProps)(ScratchOrder);
