import * as React from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
  MenuItem,
} from "@mui/material";
import { connect } from "react-redux";
import {
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  MainInput,
  centered_flex_box,
  left_flex_box,
  main_button,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, notification, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { addOrderScratch, getOrders, getProducts } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";
import { useState } from "react";
import cities from "../constants/cities.json";

export const EditOrderFulfillment = ({
  user,
  order,
  getOrders,
  isLoading
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getOrders({ orderId: id })
  }, [id])

  const [formData, setFormData] = useState({
    customer: {
      first_name: "",
      last_name: "",
    },
    phone: "",
    shipping_address: {
      address1: "",
      address2: "",
      province: "Cairo",
    },
    current_total_price: 0,
    orderId: id
  });

  useEffect(() => {
    if(order && order.orderFulfillment) {
      setFormData({
        ...formData,
        customer: {
          first_name: order.orderFulfillment.shippingAddress.first_name,
          last_name: order.orderFulfillment.shippingAddress.last_name,
        },
        phone: order.orderFulfillment.phone,
        shipping_address: {
          address1: order.orderFulfillment.shippingAddress.address1,
          address2: order.orderFulfillment.shippingAddress.address2,
          province: order.orderFulfillment.shippingAddress.province,
        },
        current_total_price: order.orderFulfillment.price
      })
    }
  }, [order])

  const canSubmit = () => {
    return formData.customer.first_name && formData.customer.last_name && formData.phone && formData.shipping_address.address1 && formData.shipping_address.province && formData.current_total_price
  }

  const handleSubmit = () => {
    if(!canSubmit()) notification.error({ message: "Please fill all required fields" });
    addOrderScratch(formData, navigate)
  }

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor4)" }}>Edit Order {order?.shopifyOrderNumber} Information</Typography>
          {/* <IconButton onClick={() => navigate("/ims/admin/products/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton> */}
        </Box>
        <Box sx={{}}>
          <Box sx={{ ...left_flex_box }}>
            <Grid container sx={{}}>
              <Grid item xs={12}>
                <Typography variant='h6'>Customer Information</Typography>
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={4}>
                    <MainInput
                      margin="normal"
                      label="First Name"
                      required
                      value={formData.customer.first_name}
                      fullWidth
                      onChange={({ target }) => setFormData({ ...formData, customer: { ...formData.customer, first_name: target.value } })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MainInput
                      margin="normal"
                      label="Last Name"
                      required
                      value={formData.customer.last_name}
                      fullWidth
                      onChange={({ target }) => setFormData({ ...formData, customer: { ...formData.customer, last_name: target.value } })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPhoneNumber
                      sx={{
                        "& label": {
                          color: "var(--secColor4) !important",
                        },
                        "& label.Mui-focused": {
                          color: "var(--secColor4) !important",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "green",
                        },
                        "& .MuiInputBase-input": {
                          outerHeight: 500,
                          color: "var(--secColor4)",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--secColor4)",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "var(--terColor)",
                          },

                          borderRadius: 0,
                        }
                      }}
                      margin="normal"
                      fullWidth
                      required
                      label={"Phone Number"}
                      variant='outlined'
                      onlyCountries={['eg']}
                      defaultCountry={'eg'}
                      countryCodeEditable={false}
                      inputProps={{
                        minLength: 13
                      }}
                      disableAreaCodes
                      value={formData.phone}
                      onChange={(value) => setFormData({ ...formData, phone: value })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>Shipping Information</Typography>
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={5}>
                    <MainInput
                      margin="normal"
                      label="Address Line 1"
                      required
                      value={formData.shipping_address.address1}
                      fullWidth
                      onChange={({ target }) => setFormData({ ...formData, shipping_address: { ...formData.shipping_address, address1: target.value } })}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <MainInput
                      margin="normal"
                      label="Address Line 2"
                      value={formData.shipping_address.address2}
                      fullWidth
                      onChange={({ target }) => setFormData({ ...formData, shipping_address: { ...formData.shipping_address, address2: target.value } })}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MainInput
                      margin="normal"
                      label="Province"
                      required
                      value={formData.shipping_address.province}
                      fullWidth
                      select
                      onChange={({ target }) => setFormData({ ...formData, shipping_address: { ...formData.shipping_address, province: target.value } })}
                    >
                      {cities.map((city) => <MenuItem key={city} value={city}>{city}</MenuItem>)}
                    </MainInput>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>Order Information</Typography>
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={4}>
                    <MainInput
                      margin="normal"
                      type="number"
                      label="Price"
                      value={formData.current_total_price}
                      fullWidth
                      onChange={({ target }) => setFormData({ ...formData, current_total_price: target.value })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button sx={{ ...main_button }} onClick={handleSubmit} loading={isLoading}>
                  Update Order Details
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  products: state?.records?.products,
  token: state?.auth?.token,
  order: state?.records?.order
});

const mapDispatchToProps = { getOrders };

export default connect(mapStateToProps, mapDispatchToProps)(EditOrderFulfillment);
