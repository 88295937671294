import * as React from 'react';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, Tabs, Tab, Card, CardHeader, CardMedia, IconButton, Autocomplete, Tooltip } from '@mui/material';
import { connect } from "react-redux";
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, MainPhone, sec_button, left_flex_box } from '../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { addInbounds, getProducts } from '../app/store/actions/dataActions';
import { Close, Delete, Download, Upload } from '@mui/icons-material';
import { useEffect } from 'react';
import Papa, { parse } from "papaparse";
import ReactFileReader from 'react-file-reader';
import { notification } from 'antd';
import download from 'downloadjs';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';
import moment from 'moment';

export const CreateInbound = ({ addInbounds, getProducts, products, user, isLoading }) => {

    const navigate = useNavigate();
    const [stockItems, setStockItems] = React.useState([]);
    const [file, setFile] = React.useState(null);
    const [isDownloading, setIsDownloading] = React.useState(false);

    const handleFiles = (files) => {
        setFile(files[0]);
    };

    useEffect(() => {
        var reader = new FileReader();
        if (file) {
            reader.onload = async (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });


                    // Assuming the first row is the header
                    const header = parsedData[0];
                    let items = [];
                    parsedData.slice(1).map((row) => {
                        const item = {};
                        row.forEach((value, index) => {
                            item[header[index]] = value;
                        });
                        let found = products.find(product => product.sku === item["Product SKU"] || product.externalId === item["Product SKU"]);
                        if (found && item["Quantity"] > 0) {
                            items.push({
                                _id: found._id,
                                name: found.name,
                                quantity: item["Quantity"],
                            });
                        }
                    });
                    setStockItems(items);
                    console.log(items);
                } catch (err) {
                    console.log(err);
                }
            };
            reader.readAsArrayBuffer(file);
        }
    }, [file]);

    const handleExportToExcel = async () => {
        setIsDownloading(true)
        notification.info({ message: "Downloading Inbound Export" })
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Inbound Products');
        workbook.creator = 'Chainz';

        // Submissions by Country
        worksheet.columns = [
            { header: 'Product SKU', key: 'sku' },
            { header: 'Product Name', key: 'name' },
            { header: 'Quantity', key: 'quantity' },
        ];

        products?.map((product) => {
            worksheet.addRow({
                "sku": product.sku || product.externalId,
                "name": product.name,
                "quantity": 0,
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        const fileName = `${user?.vendor.brandName} Inbound - ${moment().format("DD_MM_YYYY")}.xlsx`
        const blob = new Blob([buffer], { type: mimeType })
        download(blob, fileName, mimeType);
        setIsDownloading(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        var order = {
            products: stockItems
        }
        addInbounds(order, navigate);
    };

    const addItem = (value) => {
        if (value) {
            setStockItems(prevItems => {
                let found = prevItems.find(item => item.stockItem === value._id);
                if (!found) {
                    return [
                        ...prevItems,
                        {
                            _id: value._id,
                            name: value.name,
                            quantity: 1,
                        }
                    ];
                }
                return prevItems;
            });
        }
    };

    const editItem = (idx, { target }) => {
        setStockItems(prevItems => {
            let newItems = [...prevItems];
            newItems[idx].quantity = target.value;
            return newItems;
        });
    };

    const removeItem = (id) => {
        let currItems = stockItems.filter((item) => item._id !== id)
        setStockItems(currItems)
    }

    useEffect(() => {
        getProducts({ page: 1, limit: 999999 })
    }, [])

    if (isLoading) {
        return (
            <Container maxWidth="xs">
                <Box sx={{ ...centered_flex_box, minHeight: isMobile ? "100%" : "100vh", minWidth: "100%" }}>
                    <CircularProgress sx={{ color: "var(--secColor4)" }} />
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Typography my={2} variant='h4' textAlign={"center"} fontWeight={"bold"} sx={{ color: "var(--secColor4)" }}>Send Inventory</Typography>
                    <Box>
                        <Typography component="h1" variant="h5">
                            Add Via Excel file
                        </Typography>
                        <Tooltip title="Download a sample Excel file with all products">
                            <Button
                                fullWidth
                                sx={{ my: 1, ...main_button }}
                                onClick={handleExportToExcel}
                            >
                                <Download /> Excel Sample
                            </Button>
                        </Tooltip>
                        <hr />
                        <Box
                            minWidth="100%"
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{ mt: 1 }}
                        >
                            <Box minWidth="100%">
                                <ReactFileReader handleFiles={handleFiles} fileTypes={[".xlsx", ".xls"]}>
                                    <Button fullWidth sx={{ my: 1, ...main_button }}>
                                        <Upload /> Upload .xlsx{" "}
                                    </Button>
                                </ReactFileReader>
                            </Box>
                        </Box>
                    </Box>
                    <hr />
                    <Typography component="h1" variant="h5">
                        Or Add Manually
                    </Typography>
                    <hr />
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        {products && <Autocomplete
                            options={products}
                            onChange={(_, value) => addItem(value)}
                            getOptionLabel={(product) => { return `(${product.sku}) ${product.name}` }}
                            renderInput={(params) => <MainInput
                                {...params}
                                margin="normal"
                                fullWidth
                                label="Add Products"
                            />}
                        />}
                        <hr />
                        <Grid container spacing={2}>
                            {stockItems.map((product, idx) => {
                                return (
                                    <>
                                        <Grid item xs={8} sx={{ ...left_flex_box }}>
                                            <Typography>{product.name} {product.size && `(${product.size})`} {product.color && `(${product.color})`} {product.weight && `(${product.weight})`}</Typography>
                                            {/* <Typography>{product.externalId}</Typography> */}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <MainInput
                                                margin="normal"
                                                required
                                                fullWidth
                                                label="Quantity"
                                                type="number"
                                                inputProps={{ min: 1 }}
                                                value={stockItems[idx].quantity}
                                                onChange={(event) => editItem(idx, event)}
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={1} sx={{ ...centered_flex_box }}>
                                            <IconButton onClick={() => removeItem(product._id)}><Delete sx={{ color: "var(--primaryColor)" }} /></IconButton>
                                        </Grid>
                                    </>
                                )
                            })}
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, ...main_button }}
                        >
                            Send Inventory
                        </Button>
                    </Box>
                </Box>
            </Box >
        </Container >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    warehouses: state?.records?.warehouses?.docs,
    products: state?.records?.products?.docs
});

const mapDispatchToProps = { addInbounds, getProducts };

export default connect(mapStateToProps, mapDispatchToProps)(CreateInbound);