import * as React from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
  Skeleton
} from "@mui/material";
import { connect } from "react-redux";
import {
  Navigate,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import {
  centered_flex_box,
  left_flex_box,
  main_button,
  MainInput,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getExchanges, getOrders } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";
import NewCard from "../app/components/NewCard";
import Ellipse from "../app/components/Ellipse";
import { BarChart } from "@mui/x-charts";

export const Exchanges = ({
  getExchanges,
  user,
  exchanges,
  exchangeAnalysis,
  growthPercentage,
  isLoading
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = React.useState(null);
  const [terms, setTerms] = React.useState(false);
  const [page, setPage] = React.useState(parseInt(searchParams.get('page')) || 1);
  const [pageSize, setPageSize] = React.useState(parseInt(searchParams.get('pageSize')) || 10);
  const [startDate, setStartDate] = React.useState(moment(searchParams.get('startDate') || moment().startOf("month")))
  const [endDate, setEndDate] = React.useState(moment(searchParams.get('endDate') || moment().endOf("month")))
  const [filters, setFilters] = React.useState(searchParams.get('filters') ? JSON.parse(searchParams.get('filters')) : {});

  // Restore state when returning from order view
  React.useEffect(() => {
    if (location.state?.from === '/exchanges' && location.state?.state) {
      const { page, pageSize, startDate, endDate, filters } = location.state.state;
      setPage(page);
      setPageSize(pageSize);
      setStartDate(moment(startDate));
      setEndDate(moment(endDate));
      setFilters(JSON.parse(filters));
      setSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
        startDate,
        endDate,
        filters
      });
    }
  }, [location.state]);

  useEffect(() => {
    const params = {
      startDate,
      endDate,
      page,
      limit: pageSize,
      ...filters
    };
    getExchanges(params);
  }, [page, pageSize, startDate, endDate, filters])

  const handleFilter = () => {
    setPage(1);
    setSearchParams({
      page: '1',
      pageSize: pageSize.toString(),
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      filters: JSON.stringify(filters)
    });
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
    setFilters(filters);
    setSearchParams({
      page: pagination.current.toString(),
      pageSize: pagination.pageSize.toString(),
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      filters: JSON.stringify(filters)
    });
  }

  const columns = [
    {
      title: 'Shopify Number',
      dataIndex: 'shopifyOrderNumber',
      filters: [
        ...new Map(
          exchanges?.docs
            ?.filter((exchange) => exchange.originalOrder.shopifyOrderNumber !== null).map((exchange) => [
              exchange.originalOrder.shopifyOrderNumber,
              { text: exchange.originalOrder.shopifyOrderNumber, value: exchange.originalOrder.shopifyOrderNumber },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.shopifyOrderNumber?.startsWith(value),
    },
    {
      title: 'Date Created',
      dataIndex: 'date',
      sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
    },
    {
      title: 'Date Fulfilled',
      dataIndex: 'dateFulfilled',
      sorter: (a, b) => { return moment(a.dateFulfilled).isBefore(b.dateFulfilled) ? -1 : 1 },
    },
    {
      title: 'Date Delivered',
      dataIndex: 'dateDelivered',
      sorter: (a, b) => { return moment(a.dateDelivered).isBefore(b.dateDelivered) ? -1 : 1 },
    },
    {
      title: 'Customer Name',
      dataIndex: 'name',
      filters: exchanges?.docs?.filter((exchange) => exchange.customer !== null).map((exchange) => {
        return {
          text: exchange.customer?.name,
          value: exchange.customer?.name
        }
      }),
      filterSearch: true,
      onFilter: (value, record) => record?.customer?.startsWith(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: "New",
          value: "New"
        },
        {
          text: "Preparing",
          value: "Preparing"
        },
        {
          text: "Prepared",
          value: "Prepared"
        },
        {
          text: "Out for Delivery",
          value: "Out for Delivery"
        },
        {
          text: "Delivered",
          value: "Delivered"
        },
        {
          text: "Returned",
          value: "Returned"
        },
        {
          text: "Cancelled",
          value: "Cancelled"
        },
        {
          text: "Sold Out",
          value: "Sold Out"
        },
        {
          text: "Invalid",
          value: "Invalid"
        }
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, { status }) => status.toUpperCase()
    },
    {
      title: 'Actions',
      render: (_, { _id, originalOrder, incomingOrder, outgoingOrder }) =>
        <Space>
          <Button 
            sx={{ ...main_button }} 
            onClick={() => {
              const currentState = {
                page,
                pageSize,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                filters: JSON.stringify(filters)
              };
              navigate(`/orders/${originalOrder._id}`, { state: { from: '/exchanges', state: currentState } });
            }}
          >
            View Original Order
          </Button>
          <Button 
            sx={{ ...main_button }} 
            onClick={() => {
              const currentState = {
                page,
                pageSize,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                filters: JSON.stringify(filters)
              };
              navigate(`/orders/${outgoingOrder._id}`, { state: { from: '/exchanges', state: currentState } });
            }}
          >
            View Outgoing Order
          </Button>
          <Button 
            sx={{ ...main_button }} 
            onClick={() => {
              const currentState = {
                page,
                pageSize,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                filters: JSON.stringify(filters)
              };
              navigate(`/orders/${incomingOrder._id}`, { state: { from: '/exchanges', state: currentState } });
            }}
          >
            View Incoming Order
          </Button>
        </Space>
    },
  ];

  const data = exchanges?.docs?.map((row) => {
    return {
      _id: row._id,
      date: moment(row.createdAt).format("lll"),
      dateFulfilled: row.outgoingOrder.preparedAt ? moment(row.outgoingOrder.preparedAt).format("lll") : null,
      dateDelivered: row.outgoingOrder.deliveredAt ? moment(row.outgoingOrder.deliveredAt).format("lll") : null,
      status: row.outgoingOrder.status,
      customer: row.customer,
      name: row.customer.name,
      shopifyOrderNumber: row.originalOrder.shopifyOrderNumber,
      outgoingOrder: row.outgoingOrder,
      incomingOrder: row.incomingOrder,
      originalOrder: row.originalOrder
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", paddingX: 5 }}>
        <Grid container spacing={2} sx={{ ...centered_flex_box, mb: 1, overflowY: "auto" }}>
          <Grid item xs={6}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Total Exchanges</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  {!isLoading && <Ellipse size={210} stroke={15} text={exchanges?.total} />}
                  {isLoading && <Skeleton variant="circular" animation="wave" width={210} height={210} />}
                  <Typography color={"var(--primaryColor)"} fontSize={12}>{growthPercentage > 0 ? `+${growthPercentage}` : growthPercentage}% since last month</Typography>
                  <Button sx={{ ...main_button }} onClick={() => navigate("/external-exchange")}>Add External Exchange</Button>
                </Box>
              </Box>
            } />
          </Grid>
          <Grid item xs={6}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2} >Exchange Reasons</Typography>
                {isLoading && <Skeleton variant="rounded" animation="wave" width={700} height={265} />}
                {!isLoading && exchangeAnalysis && <BarChart
                  grid={{ horizontal: true }}
                  colors={["#F7463C", "#f7453c71", "#C83229", "#FF4237"]}
                  xAxis={[{ scaleType: 'band', data: ["Size Issue", "Wrong Color", "Defective Items"] }]}
                  series={[{ data: [exchangeAnalysis?.totalSizeIssues, exchangeAnalysis?.totalWrongColor, exchangeAnalysis?.totalDefective] }]}
                  width={700}
                  height={265}
                />}
              </Box>
            } />
          </Grid>
        </Grid>
        <Button sx={{ ...main_button, my: 2 }} onClick={() => navigate("/external-order")}>Add External Order</Button>
        <NewCard sx={{ mb: 2 }} content={<Box sx={{ display: "flex", flexDirection: "row", px: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography>From</Typography>
              <MainInput
                margin="normal"
                fullWidth
                type="date"
                value={startDate.format("YYYY-MM-DD")}
                onChange={({ target }) => setStartDate(moment(target.value))}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography>To</Typography>
              <MainInput
                margin="normal"
                fullWidth
                type="date"
                value={endDate.format("YYYY-MM-DD")}
                onChange={({ target }) => setEndDate(moment(target.value))}
              />
            </Grid>
            <Grid item xs={2} sx={{ ...left_flex_box, alignItems: "end" }}>
              <Button sx={{ ...main_button, mb: 2 }} onClick={() => handleFilter()}>Filter</Button>
            </Grid>
          </Grid>
        </Box>} />
        <Box sx={{ 
          width: "100%", 
          overflowX: "auto",
          "& .ant-table-wrapper": {
            width: "100%"
          },
          "& .ant-table": {
            minWidth: 1200
          },
          "& .ant-table-cell": {
            whiteSpace: "nowrap"
          }
        }}>
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={{
              current: page,
              pageSize: pageSize,
              total: exchanges?.total,
              showSizeChanger: false,
              showQuickJumper: false,
              showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
            showSorterTooltip={{
              target: 'sorter-icon',
            }}
            scroll={{ x: 1200 }}
          />
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  exchanges: state?.records?.exchanges,
  exchangeAnalysis: state?.records?.exchangeAnalysis,
  growthPercentage: state?.records?.growthPercentage
});

const mapDispatchToProps = { getExchanges };

export default connect(mapStateToProps, mapDispatchToProps)(Exchanges);
